<template>
  <header class="h" :class="{ 'border-b border-[#E4E2DF]' : scrollY > 50 }">
    <Container class="h-inner">
      <div class="h-left">
        <FadeIn slide="down" class="h-logo">
          <NuxtLink 
          to="/" 
          aria-label="Go to home page"
          @focus="togglePanel(null)"
          >
            <Logo />
          </NuxtLink>
        </FadeIn>
        <FadeIn
        slide="down"
        class="h-nav"
        :class="{ active: mobileMenu }"
        >
          <nav>
            <ul>
              <li>
                <button
                type="button"
                class="h-nav__link h-nav__link--panel" 
                :class="{ active: panel === 'features' }"
                @click="togglePanel('features')"
                >
                  Features <span :class="{ active: panel === 'features' }"></span>
                </button>
                <div 
                class="h-panel"
                :class="{ active: panel === 'features' }"
                >
                  <div class="h-panel-inner">
                    <ul>
                      <li>
                        <NuxtLink 
                        to="/features"
                        class="h-panel__link"
                        >
                          <div class="svg">
                            <ImportSvg size="sm" name="circle-check" />
                          </div>
                          <div>
                            <span>All features</span>
                            <small>Anonymous reporting, surveys, case management & analytics</small>
                          </div>
                        </NuxtLink>
                      </li>
                      <li>
                        <NuxtLink 
                        to="/security"
                        class="h-panel__link"
                        >
                          <div class="svg">
                            <ImportSvg size="sm" name="shield-quartered" />
                          </div>
                          <div>
                            <span>Security & data privacy</span>
                            <small>Data protocols, security & staff management</small>
                          </div>
                        </NuxtLink>
                      </li>
                      <li>
                        <NuxtLink 
                        to="/trust"
                        class="h-panel__link"
                        >
                          <div class="svg">
                            <ImportSvg size="sm" name="users" />
                          </div>
                          <div>
                            <span>Trust</span>
                            <small>Commitment to ethical integrity</small>
                          </div>
                        </NuxtLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <button 
                type="button"
                class="h-nav__link h-nav__link--panel" 
                :class="{ active: panel === 'cases' }"
                @click="togglePanel('cases')"
                >
                  Use cases <span :class="{ active: panel === 'cases' }"></span>
                </button>
                <div 
                class="h-panel"
                :class="{ active: panel === 'cases' }"
                >
                  <div class="h-panel-inner">
                    <ul>
                      <li>
                        <NuxtLink 
                        to="/use-cases/businesses"
                        class="h-panel__link"
                        >
                          <div class="svg">
                            <ImportSvg size="sm" name="city" />
                          </div>
                          <div>
                            <span>Businesses</span>
                            <small>Simplify compliance and secure your company's integrity with anonymous reporting tools</small>
                          </div>
                        </NuxtLink>
                      </li>
                      <li>
                        <NuxtLink 
                        to="/use-cases/universities"
                        class="h-panel__link"
                        >
                          <div class="svg">
                            <ImportSvg size="sm" name="graduation-cap" />
                          </div>
                          <div>
                            <span>Universities</span>
                            <small>Supportive pathways for staff and students to report sexual misconduct, discrimination and other behaviours</small>
                          </div>
                        </NuxtLink>
                      </li>
                      <li>
                        <NuxtLink 
                        to="/use-cases/schools"
                        class="h-panel__link"
                        >
                          <div class="svg">
                            <ImportSvg size="sm" name="school" />
                          </div>
                          <div>
                            <span>Schools</span>
                            <small>Tackle bullying and professional misconduct at school. Protect students and safeguard your school's integrity</small>
                          </div>
                        </NuxtLink>
                      </li>
                      <li>
                        <NuxtLink 
                        to="/use-cases/government"
                        class="h-panel__link"
                        >
                          <div class="svg">
                            <ImportSvg size="sm" name="building-columns" />
                          </div>
                          <div>
                            <span>Government</span>
                            <small>Improve public services and handle misconduct with integrity</small>
                          </div>
                        </NuxtLink>
                      </li>
                      <li>
                        <NuxtLink 
                        to="/use-cases/peak-bodies"
                        class="h-panel__link"
                        >
                          <div class="svg">
                            <ImportSvg size="sm" name="people-roof" />
                          </div>
                          <div>
                            <span>Peak bodies & organisations</span>
                            <small>Listen to your members and have safe support channels for all types of reporting and feedback</small>
                          </div>
                        </NuxtLink>
                      </li>
                      <li>
                        <NuxtLink 
                        to="/use-cases/aged-care-disability-services"
                        class="h-panel__link"
                        >
                          <div class="svg">
                            <ImportSvg size="sm" name="house-chimney-medical" />
                          </div>
                          <div>
                            <span>Aged care & disability services</span>
                            <small>Streamline your incident response and case management for continuous improvement and quality healthcare</small>
                          </div>
                        </NuxtLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <div class="mobile h-nav__link h-nav__link--panel">Resources</div>
                <NuxtLink
                class="h-nav__link" 
                to="/blog"
                @focus="togglePanel(null)"
                >
                  Insights
                </NuxtLink>
              </li>
              <li>
                <NuxtLink 
                class="h-nav__link mt-2 lg:mt-0" 
                to="/webinars"
                @focus="togglePanel(null)"
                >
                  Webinars
                </NuxtLink>
              </li>
              <li class="mobile">
                <div class="h-nav__link h-nav__link--panel">Company</div>
              </li>
              <li class="mobile">
                <NuxtLink 
                class="h-nav__link" 
                to="/about"
                >
                  About
                </NuxtLink>
              </li>
              <li>
                <NuxtLink 
                class="h-nav__link" 
                to="/pricing"
                @focus="togglePanel(null)"
                >
                  Pricing
                </NuxtLink>
              </li>
              <li>
                <NuxtLink 
                class="h-nav__link" 
                to="/contact"
                >
                  Contact
                </NuxtLink>
              </li>
            </ul>
          </nav>
        </FadeIn>
      </div>
      <FadeIn 
      slide="down"
      class="h-cta"
      >
        <div class="h-search pointer" @click="toggleSearch()">Search</div>
        <NuxtLink 
        to="/book-demo"
        class="inline-flex items-center transition justify-center whitespace-nowrap rounded-md border border-transparent bg-[#096b72] px-3 py-1.5 text-base font-semibold text-white shadow-sm hover:bg-[#07484b]"
        >
          Book a demo
        </NuxtLink>
        <button 
        type="button"
        class="h-toggle mobile"
        :class="{ active: mobileMenu }"
        aria-label="Toggle mobile navigation"
        @click="toggleMobileMenu"
        >
          <span></span>
          <span></span>
        </button>
      </FadeIn>
    </Container>
    <div 
    v-if="panel"
    class="h-panel-bg" 
    @click="removePanel"
    ></div>
  </header>
  <!-- <div class="h-shadow" :class="{ active: scrollY > 50 }"></div> -->

  <ElementSearchBar v-if="options.searchActive" />

</template>

<script setup>
const options = useOptionsStore()

const route = useRoute()
const panel = ref(null)
const currentUrl = ref('')
const mobileMenu = ref(false)
const scrollY = ref(0)
const searchActive = ref(false)

options.closeSearch()

const togglePanel = panelName => {
  if (panel.value === panelName) {
    panel.value = null
  } else {
    panel.value = panelName
  }
}

const toggleSearch = () => {
  options.openSearch()
}

const removePanel = () => {
  panel.value = null
}

watchEffect(() => {
  currentUrl.value = route.fullPath
  panel.value = null
  mobileMenu.value = false
  searchActive.value = false
  if (process.client) {
    document.documentElement.classList.remove('lock')
  }
})

const toggleMobileMenu = () => {
  if (mobileMenu.value) {
    mobileMenu.value = false
    document.documentElement.classList.remove('lock')
  } else {
    mobileMenu.value = true
    document.documentElement.classList.add('lock')
  }
}

const updateScrollY = () => {
  scrollY.value = window.scrollY
}

onMounted(() => {
  window.addEventListener('scroll', updateScrollY)
})

onUnmounted(() => {
  window.removeEventListener('scroll', updateScrollY)
})

</script>

<style lang="scss">
@import '@/assets/scss/_vars.scss';

.h {
  background: $white;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  padding-top: $space;
  padding-bottom: $space;
  @media (min-width: $breakpoint) {
    padding-top: $space*1.6;
    padding-bottom: $space*1.6;
  }
  svg {
    height: 30px;
    width: auto;
  }
  ul {
    display: inline-block;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    display: inline-block;
    a {
      text-decoration: none;
    }
  }
}

.h-left {
  display: flex;
}

.h-search {
  display: none;
  @media (min-width: 1100px) {
    display: inline-block;
    margin-right: 20px;
  }
}

.h-shadow {
  pointer-events: none;
  position: fixed;
  z-index: 98;
  height: 2rem;
  width: 100%;
  opacity: 0;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-duration: .3s;
  left: 0;
  background: linear-gradient(180deg,rgba(0, 0, 0, .05),transparent);
  top: 69px;
  @media (min-width: $breakpoint) {
    top: 89px;
    height: 2.5rem;
  }
  &.active {
    opacity: 1;
  }
}

.h-logo {
  a {
    display: inline-block;
  }
}

.h-cta {
  @media (max-width: calc($breakpoint - 1px)) {
    display: flex;
    justify-content: space-between;
    gap: $space*1.5;
  }
  @media (max-width: calc($breakpoint-xs - 1px)) {
    gap: $space*1;
  }
}

.h-toggle {
  background: $grey-7;
  height: 38px;
  width: 38px;
  border: none;
  border-radius: $border-radius-sm;
  position: relative;
  cursor: pointer;
  span {
    width: 24px;
    background: $color-text;
    height: 0.1rem;
    position: absolute;
    left: 7px;
    &:nth-child(1) {
      top: 13px;
    }
    &:nth-child(2) {
      top: 23px;
    }
  }
  &:hover {
    background: $grey-7;
  }
  &.active {
    background: $elker-3;
    span {
      &:nth-child(1) {
        transform: rotate(45deg);
        top: 19px;
      }
      &:nth-child(2) {
        transform: rotate(-45deg);
        top: 19px;
      }
    }
  }
}

.h-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 99;
}

.h-nav {
  @media (min-width: $breakpoint) {
    margin-left: 40px;
    position: relative;
    margin-top: 2px;
    li {
      display: inline-block;
      line-height: 1.5;
    }
  }
  @media (max-width: calc($breakpoint - 1px)) {
    display: none;

    position: fixed;
    overflow-y: scroll;
    width: 100%;
    top: 70px;
    left: 0;
    height: calc(100% - 70px);
    background: $white;
    padding-bottom: $space*2;

    li {
      display: block;
      min-height: 28px;
    }

    nav {
      padding-right: $space*2;
      padding-left: $space*2;
    }


    &.active {
      display: block;
    }
  }

  @media (max-width: calc($breakpoint-xs - 1px)) {
    nav {
      padding-right: $space;
      padding-left: $space;
    }
  }
}

@mixin h-panel__heading() {
  text-transform: uppercase;
  font-weight: 600;
  color: $elker-1;
  margin-top: $space; 
  font-size: 0.8rem;
  margin-bottom: 8px;
} 

.h-panel__heading {
  @include h-panel__heading;
}

.h-nav__link {
  cursor: pointer;
  background: transparent;
  border: 0;

  @media (max-width: calc($breakpoint - 1px)) {
    &--panel {
      cursor: default;
      @include h-panel__heading;
    }
    &:not(.h-nav__link--panel) {
      color: $color-text;
      display: inline-block;
    }
  }
  
  @media (min-width: $breakpoint) {
    display: inline-block;
    color: $color-text;
    border-radius: 0.5rem;
    line-height: 1.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    margin-left: $space*0.5;
    margin-right: $space*0.5;
    &:hover,
    &:focus {
      background: $grey-7;
    }
    &--panel {
      margin-right: $space*0.5;
      position: relative;
    }
    span {
      position: relative;
      display: inline-block;
      width: 15px;
      left: 4px;
      top: -14px;
      &.active {
        transform: rotate(180deg);
        top: 5px;
        left: -1px;
      }
      &:before,
      &:after {
        content: '';
        position: absolute;
        background: $color-text; 
        height: 0.1rem;
        width: 6px;
        top: 9px;
      }
      &:before {
        transform: rotate(45deg);
        left: 0;
      }
      &:after {
        transform: rotate(-45deg);
        left: 4px;
      }
    }
  }
  @media (min-width: 1200px) {
    margin-left: $space*0.85;
    margin-right: $space*0.85;
  }
}

.h-panel {

  a {
    color: $color-text;
  }

  @media (min-width: $breakpoint) {
    visibility: hidden;
    opacity: 0;
    transform: scale(.98) translateY(2%);
    position: absolute;
    margin-left: -10px;
    top: calc(100% + 10px);
    z-index: 101;
    background-color: #fff;
    border: 1px solid #E4E2DF;
    border-radius: $border-radius;
    box-shadow: 0 40px 80px -16px rgba($grey-4,.25), 0 20px 60px -20px rgba($grey-4,.1);
    min-width: 360px;
    max-width: 500px;
    transition: $transition-all;
    &.active {
      visibility: visible;
      opacity: 1;
      transform: scale(1) translateY(0);
    }
    ul {
      width: 100%;
    }
    li {
      line-height: 2;
      display: block;
    }
  }
}

.h-panel-bg {
  z-index: 9;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.h-panel-inner {
  @media (min-width: $breakpoint) {
    padding: $space;
  }
}

.h-panel__link {
  display: flex;
  border-radius: 0.5rem;

  @media (max-width: calc($breakpoint - 1px)) {
    small {
      display: none;
    }
  }

  &:hover {
    svg path {
      fill: #6073EE;
    }
  }

  svg {
    align-self: flex-start;
    width: 1.45em;
    margin-right: 10px;
    margin-top: 0px;
    text-align: center;
  }
  .svg {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: calc($breakpoint - 1px)) {
      svg {
        margin-top: -2px; 
      }
    }
  }

  span {
    display: block;
    line-height: 1.6;
  }
  
  @media (min-width: $breakpoint) {
    padding: $space*0.75;
    &:hover,
    &:focus {
      background: $grey-7;
    }

    small {
      display: block;
      line-height: 1.5;
      color: $grey-4;
    }

  }
}

</style>