export default defineNuxtPlugin(() => {
  return {
    provide: {
      cdn: imgPath => {
        const cdnUrl = 'https://cdn.elker.com'
        const oldImgPath = 'https://elker.b-cdn.net'  
        if (!imgPath) return ''
        if (!imgPath.includes(oldImgPath)) return imgPath
        const newImgPath = imgPath.split(oldImgPath)[1]
        return cdnUrl + newImgPath
      }
    }
  }
})