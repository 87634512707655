const defaultTitle = 'Elker - Anonymous Reporting for Employees to Speak Up'
const defaultDescription = 'Elker is an innovative speak-up and whistleblowing platform, empowering employees to make their voices heard, anonymously and safely.'

function imgPath(path) {
  const cdnUrl = 'https://cdn.elker.com'
  const bucket = 'elker.b-cdn.net'
  if (path.includes(bucket)) {
    const newImgPath = path.split(bucket)[1]
    return cdnUrl + newImgPath
  }
  return path
}

function useOpenGraphMeta(content, url, metaImage = null) {
  const { title, description, image } = content
  const img = image || metaImage
  const imageUrl = img ? img.url : 'https://cdn.elker.com/tell_us_anything_10c4c46cf2.png'
  return [
    {
      hid: 'og:description',
      property: 'og:description',
      content: description,
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: title,
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content: imgPath(imageUrl),
    },
    {
      hid: 'og:url',
      property: 'og:url',
      content: `https://elker.com${url === '/' ? '' : url}`,
    },
    {
      hid: 'og:site_name',
      name: 'og:site_name',
      content: 'Elker',
    },
    {
      hid: 'twitter:card',
      name: 'twitter:card',
      content: `summary_large_image`,
    },
    {
      hid: 'twitter:title',
      name: 'twitter:title',
      content: title,
    },
    {
      hid: 'twitter:description',
      name: 'twitter:description',
      content: description,
    },
    {
      hid: 'twitter:site',
      name: 'twitter:site',
      content: `https://elker.com${url === '/' ? '' : url}`,
    },
    {
      hid: 'twitter:image',
      name: 'twitter:image',
      content: imgPath(imageUrl),
    },
  ]
}

export default defineNuxtPlugin(() => {
  return {
    provide: {
      seo: (meta, url, options = {}) => {
        if (!meta) {
          return {
            title: defaultTitle,
            meta: [
              {
                hid: 'description',
                name: 'description',
                content: defaultDescription,
              },
              ...useOpenGraphMeta({
                title: defaultTitle,
                description: defaultDescription,
              }, url),
            ],
          }
        }
        const { metaTitle, metaDescription, metaImage, canonicalUrl } = meta
        let socialMetas = []
        const title = metaTitle
        
        socialMetas = useOpenGraphMeta({
          title,
          description: metaDescription,
        }, url, metaImage)

        // Allow for canonical URL override through options
        const canonicalHref = options.forceCanonical || `https://elker.com${url === '/' ? '' : url}`

        const metas = {
          title,
          link: [
            {
              hid: 'canonical',
              rel: 'canonical',
              href: canonicalHref,
            },
          ],
          meta: [
            {
              hid: 'description',
              name: 'description',
              content: metaDescription,
            },
            ...socialMetas.flat(),
          ],
        }
        return metas
      }
    }
  }
})