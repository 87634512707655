export const useUserStore = defineStore({

  id: 'user-store',
  persist: true,

  state: () => {
    return {
      userId: null,
      userCountry: null,
      //
      canTrk: true,
      sentToPipedrive: false,
      //
      mixpSource: null,
      mixpMedium: null,
      mixpCampaign: null,
      mixpLandingPage: null,
      mixpBotCheck: false,
      utmVisits: [],
      //
      sessionStart: null,
      removeModal3: false,
      mobileScrolledDown: false,
      mobileRemoveModal: false,
      canWatch: false
    }
  },

  actions: {

    addUtmVisit(timestamp, source = '') {
      this.utmVisits.push({
        timestamp,
        source
      })
    },

    clearOldUtmVisits() {
      const fiveHoursAgo = Date.now() - (5 * 60 * 60 * 1000)
      this.utmVisits = this.utmVisits.filter(item => item.timestamp > fiveHoursAgo)
    },

    startSession() {
      this.sessionStart = Date.now()
    },

    countUtmVisits() {
      this.clearOldUtmVisits()
      return this.utmVisits.length
    },

    checkDifferentUtms() {
      this.clearOldUtmVisits()
      let utmArr = []
      this.utmVisits.forEach(item => {
        if (!utmArr.includes(item.source)) {
          utmArr.push(item.source)
        }
      })
      return utmArr.length
    },

    doNotTrk() {
      this.canTrk = false
    },

    updateMixpUtm(source, landingPage = null, medium = null, campaign = null) {
      this.mixpSource = source
      this.mixpMedium = medium
      this.mixpCampaign = campaign
      this.mixpLandingPage = landingPage
    },

    storeUserId(id) {
      this.userId = id
    },

    isNotBot() {
      this.mixpBotCheck = true
    },

    isBot() {
      this.mixpBotCheck = false
    },

    sendUserToPipedrive() {
      this.sentToPipedrive = true
    }

  }
})


if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
