<template>
  <TransitionRoot :show="open" as="template" @after-leave="query = ''" appear>
    <Dialog as="div" class="relative z-10" @close="closeModal()">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20 md:pt-[100px]">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="ease-in duration-200" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
          <DialogPanel class="mx-auto max-w-xl transform divide-y divide-[#E4E2DF] overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
            <Combobox>
              <div class="relative">
                <Icon name="iconoir:search" class="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                <ComboboxInput 
                class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm" 
                placeholder="Search..." 
                @change="query = $event.target.value" 
                :value="query"
                autocomplete="off" 
                autocapitalize="off"
                />
              </div>

              <ComboboxOptions v-if="searchQuery && searchResults.length > 0" static class="max-h-96 transform-gpu scroll-py-3 overflow-y-auto p-3">
                <ComboboxOption v-for="item in searchResults" :value="item" as="template" v-slot="{ active }">
                  <li :class="['flex cursor-default select-none rounded-xl p-3 pointer', active && 'bg-gray-100']" @click="goToPage(item.href)">
                    <div :class="['flex h-[80px] w-[80px] flex-none items-baseline justify-center rounded-lg']">
                      <img v-if="item.image" :src="item.image" class="h-18 w-27">
                    </div>
                    <div class="ml-4 flex-auto">
                      <p :class="['text-sm font-medium', active ? 'text-gray-900' : 'text-gray-700']">
                        {{ item.title }}
                      </p>
                      <p :class="['text-sm', active ? 'text-gray-700' : 'text-gray-500']">
                        {{ item.description }}
                      </p>
                    </div>
                  </li>
                </ComboboxOption>
              </ComboboxOptions>

              <div v-if="searchQuery && searchResults.length < 1" class="px-6 py-14 text-center text-sm sm:px-14">
                <Icon name="iconoir:warning-circle" class="mx-auto h-6 w-6 text-gray-400" />
                <p class="mt-4 font-semibold text-gray-900">No results found</p>
                <p class="mt-2 text-gray-500">No pages found for this search term. Please try again.</p>
              </div>
              
            </Combobox>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'

const options = useOptionsStore()
const userStore = useUserStore()
const { result, search } = useAlgoliaSearch('prod_ELKER')
const router = useRouter()
const { canTrk } = storeToRefs(userStore)

const open = ref(true)
const query = ref('')

const searchResults = ref(null)
const searchQuery = ref(null)

const closeModal = () => {
  open.value = false
  options.closeSearch()
}

const goToPage = async href => {
  router.push({ path: href })

  setTimeout(() => {
    open.value = false
    options.closeSearch()
  }, 100)
}

const filterResults = results => {
  if (!results) return null
  return results.filter(result => {
    const { href } = result
    return !(
      href.includes('/webinars/') ||
      href.includes('/policies/') ||
      href.includes('/portal/')
    )
  })
}

watch(
  query,
  async () => {
    const { data } = await useAsyncData(
      'ssr-search-results',
      () => search({ query: query.value }) 
    )

    searchResults.value = filterResults(data.value?.hits)
    searchQuery.value = data.value?.query
    
  },
  { immediate: true }
)

</script>