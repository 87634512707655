<template>
  
  <div class="app" ref="refocus" tabindex="-1">
    <Header />
    <main>
      <div class="mx-auto mt-20 max-w-2xl text-center sm:mt-24 px-4">
        <p class="text-pretitle">404</p>
        <h1 class="text-h1">This page does not exist</h1>
        <p class="text-subtitle">Sorry, we couldn't find the page you're looking for.</p>
      </div>
      <div class="mx-auto my-16 flow-root max-w-lg sm:my-20 px-4">
        <h2 class="sr-only">Popular pages</h2>
        <ul role="list" class="-mt-6 divide-y divide-[#E4E2DF] border-b border-[#E4E2DF]">
          <li class="relative flex gap-x-6 py-6">
            <div class="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-[#E4E2DF]">
              <Icon name="iconoir:security-pass" color="#6073EE" class="h-6 w-6 text-indigo-600" />
            </div>
            <div class="flex-auto">
              <h3 class="text-sm font-semibold leading-6 text-gray-900">
                <NuxtLink to="/features">
                  <span class="absolute inset-0" />
                  Features
                </NuxtLink>
              </h3>
              <p class="text-sm leading-6 text-gray-600">Anonymous reporting, surveys, case management & analytics</p>
            </div>
            <div class="flex-none self-center">
              <Icon name="iconoir:arrow-right" />
            </div>
          </li>
          <li class="relative flex gap-x-6 py-6">
            <div class="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-[#E4E2DF]">
              <Icon name="iconoir:design-nib" color="#6073EE" class="h-6 w-6 text-indigo-600" />
            </div>
            <div class="flex-auto">
              <h3 class="text-sm font-semibold leading-6 text-gray-900">
                <NuxtLink to="/blog">
                  <span class="absolute inset-0" />
                  Blog
                </NuxtLink>
              </h3>
              <p class="text-sm leading-6 text-gray-600">View the latest insights into workplace reporting, surveys and analytics</p>
            </div>
            <div class="flex-none self-center">
              <Icon name="iconoir:arrow-right" />
            </div>
          </li>
          <li class="relative flex gap-x-6 py-6">
            <div class="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-[#E4E2DF]">
              <Icon name="iconoir:chat-bubble" color="#6073EE" class="h-6 w-6 text-indigo-600" />
            </div>
            <div class="flex-auto">
              <h3 class="text-sm font-semibold leading-6 text-gray-900">
                <NuxtLink to="/contact">
                  <span class="absolute inset-0" />
                  Contact us
                </NuxtLink>
              </h3>
              <p class="text-sm leading-6 text-gray-600">Speak to us about how we can improve your reporting channels</p>
            </div>
            <div class="flex-none self-center">
              <Icon name="iconoir:arrow-right" />
            </div>
          </li>
          <li class="relative flex gap-x-6 py-6">
            <div class="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-[#E4E2DF]">
              <Icon name="iconoir:app-window" color="#6073EE" class="h-6 w-6 text-indigo-600" />
            </div>
            <div class="flex-auto">
              <h3 class="text-sm font-semibold leading-6 text-gray-900">
                <NuxtLink to="/contact">
                  <span class="absolute inset-0" />
                  Book a demo
                </NuxtLink>
              </h3>
              <p class="text-sm leading-6 text-gray-600">Book a demonstration of our anonymous reporting platform and app.</p>
            </div>
            <div class="flex-none self-center">
              <Icon name="iconoir:arrow-right" />
            </div>
          </li>
        </ul>
        <div class="mt-10 flex justify-center">
          <NuxtLink to="/" class="text-sm font-semibold leading-6 text-indigo-600">
            <span>&larr;</span>
            Back to home
          </NuxtLink>
        </div>
      </div>
    </main>
    <Footer />
  </div>

</template>

<script setup>
const route = useRoute()

const seo = computed(() => {
  return {
    metaTitle: '404 - '+route.fullPath,
    metaDescription: '',
    metaImage: ''
  }
})

useHead(seo)
</script>